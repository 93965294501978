/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap"); */
* {
  /* outline: 1px solid green; */
}
.paystackBtn {
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #1dc214d7;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
}
.paystackBtnPayLater {
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #1dc214d7;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
}
.header {
  margin-right: "20px";
  font-size: "18px";
  background-color: #f1f1f1;
}

.header:hover {
  background-color: #666;
  color: #2aa55b;
}
.unassign {
  background-color: red;
  color: #ffff;
  width: 90px;
  height: 30px;
}

.header.active {
  color: red;
}

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 930px;
  height: 550px;
  border-radius: 10px;
  margin-left: 230px;
  /* display: flex; */
  /* justify-content: center; */
  margin-top: 80px;
}

.image-slider {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .image {
  width: 450px;
  height: 300px;
  border-radius: 12px;
} */
.left {
  position: absolute;
  left: 3rem;
  top: 50%;
  font-size: 1rem;
  color: blue;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}
.right {
  position: absolute;
  right: -13rem;
  top: 50%;
  font-size: 2rem;
  color: blue;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}
.currentSlide {
  transition-duration: 0.5s ease;
  opacity: 1;
}
.currentSlide.active {
  opacity: 1;
  transition-duration: 0.5s;
  transform: scale(1.2);
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Poppins", sans-serif;
} */
.header {
  background-color: #0290d5;
}
